import React from 'react'
import { Link } from 'gatsby'
import { employes } from 'images'
import { window } from 'browser-monads'
import PricingColumn from 'components/pricing-column'
import CtaRow from 'components/cta-row'
import Helmet from 'react-helmet'
import Layout from '../../../layouts'
import { FaStar, FaEuroSign } from 'react-icons/fa'
import Stars from 'components/stars'

const comparisonData = [
  {
    label: 'Doelgroep',
    label_class: 'badge aqua',
    title_column_1: 'Alle soorten bedrijven',
    text_column_1: 'Een salarisadministrateur kan eigenlijk alle soorten bedrijven verlonen.',
    title_column_2: 'Midden en grootbedrijf en salarisprofessionals',
    text_column_2: 'Bestaande pakketten over het algemeen zeer uitgebreid en geschikt voor mensen met vergevorderde salariskennis.',
    title_column_3: 'MKB & DGA`s',
    text_column_3: 'Geen salariskennis nodig waardoor het zeer geschikt is voor midden- en kleinbedrijf.'
  },

  {
    label: 'Gemak',
    label_class: 'badge orange',
    title_column_1: [<Stars key="1" checked="checkedStar"/>, <Stars key="2" checked="checkedStar"/>,<Stars key="3" checked="checkedStar"/>, <Stars key="4" checked="checkedStar"/>,<Stars key="5" checked="uncheckedStar"/>],
    text_column_1: 'Een salarisadministrateur neemt veel werk voor je uit handen. Maar werken met derden vereist natuurlijk wel extra communicatie.',
    title_column_2: [<Stars key="1" checked="checkedStar"/>, <Stars key="2" checked="checkedStar"/>,<Stars key="3" checked="uncheckedStar"/>, <Stars key="4" checked="uncheckedStar"/>,<Stars key="5" checked="uncheckedStar"/>],
    text_column_2: 'Bestaande pakketten zijn echt bedoeld voor salarisexperts en zitten door de hoeveelheid aan functies dus niet altijd even logisch in elkaar. Weet jij bijvoorbeeld wat je Whk-premie is?',
    title_column_3: [<Stars key="1" checked="checkedStar"/>, <Stars key="2" checked="checkedStar"/>,<Stars key="3" checked="checkedStar"/>, <Stars key="4" checked="checkedStar"/>,<Stars key="5" checked="checkedStar"/>],
    text_column_3: 'Gemak, dat is waar het bij Employes om draait. Zonder salariskennis kun je eenvoudig medewerkers verlonen. De gemiddelde tijd voor salarisverwerking is minder 6 minuten.'
  },
  {
    label: 'Ondersteuning',
    label_class: 'badge aqua',
    title_column_1: [<Stars key="1" checked="checkedStar"/>, <Stars key="2" checked="checkedStar"/>,<Stars key="3" checked="checkedStar"/>, <Stars key="4" checked="checkedStar"/>,<Stars key="5" checked="checkedStar"/>],
    text_column_1: 'Hét voordeel van uitbesteden is dat je op alle salarisvragen en aanverwante vragen een antwoord kunt verwachten. ',
    title_column_2: [<Stars key="1" checked="checkedStar"/>, <Stars key="2" checked="uncheckedStar"/>,<Stars key="3" checked="uncheckedStar"/>, <Stars key="4" checked="uncheckedStar"/>,<Stars key="5" checked="uncheckedStar"/>],
    text_column_2: '"Komt u er niet uit? Richt u zich dan op uw accountant of salarisspecialist", dat is wat de meest klantvriendelijke salarispakketten schrijven op hun site. Zelfredzaamheid is dus een vereiste.',
    title_column_3: [<Stars key="1" checked="checkedStar"/>, <Stars key="2" checked="checkedStar"/>,<Stars key="3" checked="checkedStar"/>, <Stars key="4" checked="checkedStar"/>,<Stars key="5" checked="uncheckedStar"/>],
    text_column_3: 'Onze klantenservice bestaat uit salarisexperts die je kunnen helpen bij vragen op het gebied van salarisverwerking. Daarnaast helpen we je de eerste keer bij het instellen van je salarisinstelling.'
  },
  {
    label: 'Functies',
    label_class: 'badge blue',
    title_column_1: [<Stars key="1" checked="checkedStar"/>, <Stars key="2" checked="checkedStar"/>,<Stars key="3" checked="uncheckedStar"/>, <Stars key="4" checked="uncheckedStar"/>,<Stars key="5" checked="uncheckedStar"/>],
    text_column_1: 'De dienstverlening is per kantoor verschillend. Tegen betaling willen accountants en boekhouder vaak veel voor je doen. Maar functies als online verlof aanvragen zul je hier niet vinden.',
    title_column_2: [<Stars key="1" checked="checkedStar"/>, <Stars key="2" checked="checkedStar"/>,<Stars key="3" checked="checkedStar"/>, <Stars key="4" checked="checkedStar"/>,<Stars key="5" checked="checkedStar"/>],
    text_column_2: 'De meest bekende softwarepakketten bieden een zeer uitgebreide lijst aan features. Verzin het, en het zit er in. Heb je een organisatie met managementlagen en zoek je bijvoorbeeld veel HRM-functies? Kies dan één van de grote bestaande pakketten.',
    title_column_3: [<Stars key="1" checked="checkedStar"/>, <Stars key="2" checked="checkedStar"/>,<Stars key="3" checked="checkedStar"/>, <Stars key="4" checked="uncheckedStar"/>,<Stars key="5" checked="uncheckedStar"/>],
    text_column_3: 'Naast alle denkbare functies voor salarisadministratie kun je met Employes bijvoorbeeld ook contracten maken, verlof aanvragen, documenten delen, een dossier bijhouden. Alle functies van belang voor een MKB bedrijf zitten erin.'
  },
  {
    label: 'Foutgevoelig',
    label_class: 'badge blue',
    title_column_1: [<Stars key="1" checked="checkedStar"/>, <Stars key="2" checked="checkedStar"/>,<Stars key="3" checked="uncheckedStar"/>, <Stars key="4" checked="uncheckedStar"/>,<Stars key="5" checked="uncheckedStar"/>],
    text_column_1: 'Door de extra communicatie is er meer kansen op fouten. Tegelijkertijd heb je wel een expert die met je meekijkt.',
    title_column_2: [<Stars key="1" checked="checkedStar"/>, <Stars key="2" checked="uncheckedStar"/>,<Stars key="3" checked="uncheckedStar"/>, <Stars key="4" checked="uncheckedStar"/>,<Stars key="5" checked="uncheckedStar"/>],
    text_column_2: 'Doordat je zelf veel salarisinstellingen moet invoeren, is de kans op fouten groter. Je bent hierbij echt op jezelf aangewezen.',
    title_column_3: [<Stars key="1" checked="checkedStar"/>, <Stars key="2" checked="checkedStar"/>,<Stars key="3" checked="checkedStar"/>, <Stars key="4" checked="checkedStar"/>,<Stars key="5" checked="uncheckedStar"/>],
    text_column_3: 'Je wordt als het ware door de software heen geloodst waardoor de kans op fouten nihil is.'
  },
  {
    label: 'Snelheid',
    label_class: 'badge orange',
    title_column_1: [<Stars key="1" checked="checkedStar"/>, <Stars key="2" checked="uncheckedStar"/>,<Stars key="3" checked="uncheckedStar"/>, <Stars key="4" checked="uncheckedStar"/>,<Stars key="5" checked="uncheckedStar"/>],
    text_column_1: 'Het nadeel van uitbesteden is dat dat je afhankelijk bent van derden. Boekhouder op vakantie? Pech gehad.',
    title_column_2: [<Stars key="1" checked="checkedStar"/>, <Stars key="2" checked="checkedStar"/>,<Stars key="3" checked="checkedStar"/>, <Stars key="4" checked="uncheckedStar"/>,<Stars key="5" checked="uncheckedStar"/>],
    text_column_2: 'De salarisverwerking in "eigen handen" zorgt voor optimale controle en snelheid. Als je eenmaal het pakket begrijpt dan kun je redelijk efficiënt je salarisverwerking doen.',
    title_column_3: [<Stars key="1" checked="checkedStar"/>, <Stars key="2" checked="checkedStar"/>,<Stars key="3" checked="checkedStar"/>, <Stars key="4" checked="checkedStar"/>,<Stars key="5" checked="checkedStar"/>],
    text_column_3: 'Met Employes kun je snel de salarisverwerking doen. De gemiddelde tijd om een verloning te doen, is minder dan 6 minuten.'
  },
  {
    label: 'Tarieven',
    label_class: 'badge orange',
    title_column_1: '€ 10 - € 20 per loonstrook',
    text_column_1: 'Voor alle zaken naast de reguliere dienstverlening dient betaald te worden. Denk hierbij aan de kosten bij de invoer van een werknemer of een jaaropgave',
    title_column_2: 'Uiteenlopende tarieven',
    text_column_2: 'Afhankelijk van de bedrijfsgrootte, verschillen de tarieven. Als klein bedrijf met 5 werknemers betaal je al snel € 10,- per loonstrook. Voor grotere bedrijven wordt het al snel goedkoper.',
    title_column_3: '€ 5 per loonstrook',
    text_column_3: 'De kosten voor salarisverwerking zijn € 27,50 per maand plus € 6,- per loonstrook. Voor salaris & HR of een DGA verloning, <a href="/tarieven/bedrijf-met-personeel/" className="link inline"> bekijk de tarieven.</a>'
  }
]


class VergelijkenPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      active: false,
      activeID: ''
    }

    this.setActive = this.setActive.bind(this)
  }

  setActive(event) {
    let id = event.target.id

    if (id === this.state.activeID) {
      id = ''
    }

    this.setState({
      active: !this.state.active,
      activeID: id
    })
  }

  render() {
    return (
      <Layout location={this.props.location}>
        <main className="vergelijken animated fadeInPage">

          <Helmet>
            <title>Bekijk de verschillende opties voor salarisadministratie.</title>
            <meta name="description" content="Welke manier van salarisadministratie past het beste bij mijn bedrijf? Uitbesteden of toch zelf doen? En welk salarispakket? Bekijk het totaal overzicht." />
            <meta itemprop="name" content="Vergelijk de verschillende opties voor salarisadministratie." />
            <meta itemprop="description" content="Welke manier van salarisadministratie past het beste bij mijn bedrijf? Uitbesteden of toch zelf doen? En welk salarispakket? Bekijk het totaal overzicht."/>
            <meta itemprop="image" content="/static/meta-img.png" />
          </Helmet>

          <div className="container-md padding-xxl">
            <div className="grid center text-center">
              <div className="col-8">
                <h1>Welke manier van salarisadministratie past het beste bij mij?</h1>
                <p className="streamer large center">Er zijn veel verschillende manieren om de salarisadministratie van je bedrijf te regelen, bijvoorbeeld via een boekhouder of een bestaand salarispakket. Bekijk hieronder de verschillen.</p>
              </div>
            </div>
          </div>


          <div className="container-md margin-m-top">
            <div className="backdrop">
            </div>
            <div className="rows">
              <div className="grid yg row">
                <div className="col-3">
                </div>
                <div className="col-3">
                  
                  
                  <h4>Employes</h4>

                </div>
                <div className="col-3">
                  <h4>Traditioneel salarispakket</h4>
                </div>
                <div className="col-3">
                  <h4>Via een accountant of boekhouder</h4>
                </div>
              </div>

              { comparisonData.map((data, idx) => (

                <div key={idx} className="grid yg row">
                  <div className="col-3">
                    <span className={data.label_class}><b>{data.label}</b></span>
                  </div>
                  <div className="col-3">
                    <h5>{data.title_column_3}</h5>
                    <p dangerouslySetInnerHTML={ { __html: data.text_column_3 } }></p>
                  </div>
                  <div className="col-3">
                    <h5>{data.title_column_2}</h5>
                    <p dangerouslySetInnerHTML={ { __html: data.text_column_2 } }></p>
                  </div>
                  <div className="col-3">
                    <h5>{data.title_column_1}</h5>
                    <p dangerouslySetInnerHTML={ { __html: data.text_column_1 } }></p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <section className="vision padding-l-top padding-xl-bottom">
            <div className="container-sm">
              <div className="grid yg">
                <div className="col-12">
                  <p className="eyebrow">Conclusie</p>
                  <h3>De beste keuze voor je bedrijf</h3>
                </div>
                <div className="col-6">
                  <p className="margin-s-bottom">
                    Welke manier van salarisadministratie het beste bij je past, is sterk afhankelijk van je wensen en de bedrijfsgrootte. We kunnen niet zomaar stellen dat één methode de beste of slechtste is. Maar de verschillen zijn wel groot. Als je geen salarisexpert binnen je bedrijf hebt (zoals een HR-manager), dan zijn de bestaande salarispakketten al minder geschikt voor je. Wat overblijft is dan de keuze tussen salarisadministratie zelf doen met Employes of uitbesteden bij een plaatselijke accountant of een administratiekantoor.<br/><br/> Salarisadministratie wordt vaak gezien als ingewikkeld en tijdrovend. Toch hoeft dit tegenwoordig echt niet meer zo te zijn.
                  </p>

                </div>
                <div className="col-6">
                  <p className="margin-s-bottom">
                    Met Employes wordt al bijna alles automatisch voor je gedaan. Hierdoor heb je als ondernemer geen adminstrateur meer nodig. Ook niet als je geen salariskennis hebt. <br/><br/> Heb je een maand geen wijzigingen in je salarisadministratie? Dan kun je kunt zelfs met één druk op de knop je salarisadministratie doen. Het zal een kwestie van tijd zijn voor accountants en administratiekantoren zich uitsluitend zullen richten op advieswerk. Eén ding in is zeker, salarisadministratie gebeurt voortaan automatisch.
                  </p>
                  <p className="seo-link">Tags:&nbsp;
                    <Link className="seo-link" to="/salarisadministratie-uitbesteden/">Salarisadministratie uitbesteden</Link>,&nbsp;
                    <Link className="seo-link" to="/salarispakket/">Salarispakket</Link>,&nbsp;
                    <Link className="seo-link" to="/salaris-software/">Salaris-software</Link>,&nbsp;
                    <Link className="seo-link" to="/personeelsadministratie/">Personeelsadministratie</Link>,&nbsp;
                    <Link className="seo-link" to="/salarisadministratie-zelf-doen/">Salarisadministratie-zelf-doen</Link>,&nbsp;
                    <Link className="seo-link" to="/salarisverwerking/">Salarisverwerking</Link>,&nbsp;
                    <Link className="seo-link" to="/loon-software/">Loon-software</Link>,&nbsp;
                    <Link className="seo-link" to="/loonadministratie/">Loonadministratie</Link>,&nbsp;
                  </p>
                </div>
              </div>
            </div>
          </section>
        </main>
      </Layout>
    )
  }
}

export default VergelijkenPage
